import React, {Component} from 'react';
import StormarnMap from "../StormarnMap/StormarnMap";
import MapMarker from "../MapMarker/MapMarker";
import base_map from "../../utils/stormarn_map";
import {GeoJSON} from "react-leaflet";

class SmallMap extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { geojson, items } = this.props;
        console.log(geojson);
        return (
            <StormarnMap items={items} zoom={14} showStormarn={false}>
                {geojson ? (
                  <GeoJSON data={geojson} style={{
                      "color": "#22627C",
                      "weight": 5,
                      "opacity": 0.8
                  }}/>
                ) : null}
                {items.map((item, index) => (
                    <MapMarker key={index}
                        position={[
                            parseFloat(item.latitude),
                            parseFloat(item.longitude)
                        ]}
                    >
                        {item.content}
                    </MapMarker>
                ))}
            </StormarnMap>
        );
    }
}

export default SmallMap;
