import React from 'react';
// import cookie from 'react-cookie';
// import ReactSelectize from 'react-selectize';
// const SimpleSelect = ReactSelectize.SimpleSelect;

import Select from 'react-select';

export default class ArtFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: []
        };
        this.changeFilter = this.changeFilter.bind(this);
    }
    dofetch(props) {
        const that = this;

        const url = props.url;
        fetch(url)
            .then(function(response) {
                //Error handling? Response is always 200
                return response.json();
            }).then(function(json) {
                // const filters = {};
                // json.filters.forEach(function(category) {
                //     filters[category.ID] = category;
                // });
                that.setState({
                    filters: json.filters
                });
            }).catch(function(ex) {
                console.log('parsing failed', ex);
            });
    }
    componentDidMount() {
        this.dofetch(this.props);
    }
    componentWillReceiveProps(newProps) {
        this.dofetch(newProps);
    }
    changeFilter(filterid, option) {
        const filtermap = {};
        if(option === null) {
            filtermap[filterid] = '';
        } else {
            filtermap[filterid] = option.value;
        }
        this.props.navigate(filtermap);
    }
    render() {
        // console.log(this.props.query);
        return <div className="header_filter">
            <form>
            {
                this.state.filters.map((filter, i) =>
            { return <div key={i} className="header_filter_field">
                <Select onChange={(option) => this.changeFilter(filter.id, option)}
                    value={this.props.query[filter.id]}
                    placeholder={filter.title}
                    searchable={false}
                    multi={false}
                    options={
                        filter.values.map((value, i) => { return {
                            label: value.Title,
                            value: value.ID
                        }} )
                    }
                    />
            </div>})}
            </form>
       </div>;
    }
}