// @flow
import React from 'react';
import {Marker, Popup} from 'react-leaflet';
import L from "leaflet";

import { person, people, home } from './icons';

const iconMap = {
    person,
    people,
    home,
};

const MapMarker = ({position, children, icon, content, onClick, hover, extraClass}) => {

    if(icon)
        content = iconMap[icon];

    const markerIcon = L.divIcon({
        className: 'map-marker ',
        popupAnchor: [0, -30],
        iconSize: null,
        html: '<div class="mapicon ' + (icon?'mapicon--icon':'') + (extraClass ? ` ${extraClass}` : '')+  '">' +
            content +
            // '<i class="material-icon">'+icon+'</i>' +
        '</div>',
    });

    return <Marker position={position} icon={markerIcon}
                   onMouseOver={(e) => {
                       if(hover)
                        e.target.openPopup();
                   }}
                   onMouseOut={(e) => {
                       if(hover)
                        e.target.closePopup();
                   }}
                   onClick={(e) => onClick(e)}
    >
        {children?<Popup>
            <div dangerouslySetInnerHTML={{__html: children}}></div>
        </Popup>:null}
    </Marker>
};

MapMarker.defaultProps = {
    children: null,
    icon: false,
    content: '',
    onClick: () => {},
};

export default MapMarker;
