// @flow

import React, { Component } from 'react';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';

const BaseMap = (props) => {
    const {center, zoom, height, bounds, boundsOptions, children} = props;
    return (
        <LeafletMap center={center} zoom={zoom} style={{height: height}} bounds={bounds} boundsOptions={boundsOptions} scrollWheelZoom={false}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
            />
            {children}
        </LeafletMap>
    );
};

BaseMap.defaultProps = {
    lat: 0,
    lng: 0,
    zoom: 10,
    height: '100%',
    bounds: null
};

export default BaseMap;
