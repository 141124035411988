import React, {Component} from 'react';
import GridItem from "../GridItem/GridItem";
import Filters from "../Filters/Filters";
import URI from "urijs";

class FilterableList extends Component {
    constructor(props) {
        super(props);
        this.parseLocation = this.parseLocation.bind(this);
        this.navigate = this.navigate.bind(this);
        const location = window.location.hash.replace(/^#\/?|\/$/g, '');
        this.state = {
            query: this.parseLocation(location)
        };
    }
    parseLocation(location) {
        const params = URI.parseQuery(location);
        return params;
    }
    componentWillReceiveProps(newProps) {
        // this.setState({query: this.parseLocation(newProps.location)});
    }
    navigate(change) {
        const query = Object.assign(this.state.query, change);
        window.location.href =
            window.location.pathname + window.location.search + '#' + URI.buildQuery(query)
        ;
        this.setState({query: query});
    }

    render() {
        const {items, filters} = this.props;

        const {query} = this.state;

        const filteredItems = items.filter(item => {
           if(query.city && query.city !== item.topline)
               return false;
            if(query.year && item.subline.search(query.year) === -1)
                return false;
           return true;
        });

        return (
            <>
                <header className="header">
                    <div className="header_content">
                        <h1><span>Alle Kunstorte</span></h1>
                        {filters ? <Filters navigate={this.navigate} query={query} filters={filters}/> : null }
                    </div>
                </header>
                <section className="section section--grid section--grid-small">
                    <div className="section_content">
                        <div className="grid_list">
                            {filteredItems.map(item => <GridItem {...item}/>)}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FilterableList;
