import React, {Component} from 'react';
import URI from "urijs";
import MapMarker from "../MapMarker/MapMarker";
import CulturalPlaceItem from "../CulturalPlaceItem/CulturalPlaceItem";
import StormarnLauenburgMap from "../StormarnLauenburgMap/StormarnLauenburgMap";

const parseLocation = (location) => {
    const params = URI.parseQuery(location);
    return params;
}

class CulturalPlaceList extends Component {
    constructor(props) {
        super(props);
        this.navigate = this.navigate.bind(this);
        this.hashChange = this.hashChange.bind(this);
        const location = window.location.hash.replace(/^#\/?|\/$/g, '');
        this.state = {
            query: parseLocation(location)
        };
    }

    hashChange() {
        const location = window.location.hash.replace(/^#\/?|\/$/g, '');
        this.setState({
            query: parseLocation(location)
        });
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.hashChange, false);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.hashChange, false);
    }

    navigate(change) {
        const query = Object.assign(this.state.query, change);
        window.location.href =
            window.location.pathname + window.location.search + '#' + URI.buildQuery(query)
        ;
        // this.setState({query: query});
    }

    render() {
        const {items, filters} = this.props;

        const {query} = this.state;

        let mapItems = [];

        const filteredItems = items.filter(item => {
            if(query.category && query.category !== item.category)
                return false;
            if(query.city && query.city !== item.topline)
                return false;
            if(query.year && item.subline.search(query.year) === -1)
                return false;
            return true;
        });

            const itemMap = filteredItems.reduce((result, item) => {
                if(!result[item.latitude + '/' + item.longitude]) {
                    result[item.latitude + '/' + item.longitude] = Object.assign({
                        icon: 'person',
                        content: '<a href="'+item.href+'">'+item.headline+'</a>'
                    }, item)
                } else {
                    result[item.latitude + '/' + item.longitude].icon = 'people';
                    result[item.latitude + '/' + item.longitude].content += '<br/>' + '<a href="'+item.href+'">'+item.headline+'</a>';
                }

                return result;
            }, {});
            mapItems = Object.values(itemMap);
            console.log(mapItems);
            //Group

        return (
            <>
                <header className="stage">
                    <div className="map">
                        <StormarnLauenburgMap
                          items={filteredItems}
                          zoom={14}
                        >
                            {mapItems.map(item => (
                                <MapMarker
                                    position={[
                                        parseFloat(item.latitude),
                                        parseFloat(item.longitude)
                                    ]}
                                    onClick={(e) => {
                                        window.location.href=item.href
                                        // if(query.city) {
                                        //     // window.location.href=item.href;
                                        //     return;
                                        // }
                                        //
                                        // this.navigate({city: item.content});
                                    }}
                                    hover={!query.city}
                                    // icon={item.icon}
                                    extraClass={`mapicon--cp-${item.category}`}
                                    content={query.city ? '' : item.number}
                                >
                                    {item.headline}
                                </MapMarker>
                            ))}
                        </StormarnLauenburgMap>
                    </div>
                </header>
                <section className="section section--cp-categories">
                    <div className="section_content">
                        {filters.map(filter => (
                          <ul className="category-list">
                              {filter.values.map(value => (
                                <li
                                  onClick={() => this.navigate({category: query.category===value.ID ? null : value.ID})}
                                >
                                    <span className={`cp-icon cp-icon--${value.ID}${query.category===value.ID ? '-hi' : ''}`}></span>
                                    <span className={`cp-tag cp-tag--${value.ID}`} dangerouslySetInnerHTML={{__html: value.Title}}></span>
                                </li>
                              ))}
                          </ul>
                        ))}
                    </div>
                </section>
                <section className="section section--grid section--grid-small">
                    <div className="section_content">
                        <div className="grid_list">
                            {filteredItems.map(item => <CulturalPlaceItem {...item}/>)}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default CulturalPlaceList;
