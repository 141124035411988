import React from 'react';

import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

export default class MapElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    getContent() {
        return {__html: this.props.content};
    }
    render() {
        const position = this.props.position;
        const zoom = this.props.zoom||11;
        const icon = L.divIcon({
            className: 'map-marker',
            iconSize:null,
            html:'<div class="icon"></div>'
        });
        return <Map center={position} zoom={zoom}>
                <TileLayer
                  url="http://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position}>
                  <Popup>
                    <div dangerouslySetInnerHTML={this.getContent()}></div>
                  </Popup>
                </Marker>
            </Map>;
    }
}