// @flow

import React, {Component} from 'react';
import BaseMap from "../BaseMap/BaseMap";
import base_map from "../../utils/stormarn_map";
import {GeoJSON} from "react-leaflet";
import L from "leaflet";


export default class StormarnMap extends Component {
    render() {
        let bounds = L.geoJSON(base_map).getBounds();
        let boundsOptions={};
        let center = 0;

        if(this.props.items && this.props.items.length > 1 && this.props.zoom) {
            //Center on items with zoom as maxzoom. MaxZoom does not work
            bounds = this.props.items.map((item) => [item.latitude, item.longitude]);
            boundsOptions={maxZoom: this.props.zoom, padding: [30,50]}
        }
        if(this.props.items && this.props.items.length == 1 && this.props.zoom) {
            bounds = null;
            center = [this.props.items[0].latitude, this.props.items[0].longitude]
        }

        return <BaseMap center={center} bounds={bounds} boundsOptions={boundsOptions} height={this.props.height} zoom={this.props.zoom}>
            {this.props.showStormarn
                ? <GeoJSON data={base_map} style={{
                    "color": "#22627C",
                    "weight": 0,
                    "opacity": 0.65
                }} ref={ref => this.geojson = ref}/>
                : null }
            {this.props.children}
        </BaseMap>;
    }
}

StormarnMap.defaultProps = {
    showStormarn: true,
};
