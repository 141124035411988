import React from 'react';
import Select from 'react-select';

export default class Filters extends React.Component {
    constructor(props) {
        super(props);
        // this.changeFilter = this.changeFilter.bind(this);
        console.log(props);
    }
    changeFilter(filterid, option) {
        const filtermap = {};
        if(option === null) {
            filtermap[filterid] = '';
        } else {
            filtermap[filterid] = option.value;
        }
        this.props.navigate(filtermap);
    }
    render() {
        return <form>
                {
                    this.props.filters.map((filter, i) =>
                    {
                        return <div key={i} className="header_filter_field">
                        <Select onChange={(option) => this.changeFilter(filter.id, option)}
                                value={this.props.query[filter.id] && {
                                    label: this.props.query[filter.id],
                                    value: this.props.query[filter.id]
                                }}
                                placeholder={filter.title}
                                searchable={false}
                                multi={false}
                                isClearable={true}
                                isSearchable={false}
                                options={
                                    filter.values.map((value, i) => { return {
                                        label: value.Title,
                                        value: value.ID
                                    }} )
                                }
                        />
                    </div>})}
        </form>;
    }
}
