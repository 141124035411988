// @flow

import React, {Component} from 'react';
import BaseMap from "../BaseMap/BaseMap";
import stormarn_map from "../../utils/stormarn_map";
import {GeoJSON} from "react-leaflet";
import L from "leaflet";
import lauenburg_map from "../../utils/lauenburg_map";


export default class StormarnLauenburgMap extends Component {
  render() {
    let bounds = L.geoJSON(stormarn_map).getBounds();
    let boundsOptions = {};
    let center = 0;

    if (this.props.items && this.props.items.length > 1 && this.props.zoom) {
      //Center on items with zoom as maxzoom. MaxZoom does not work
      bounds = this.props.items.map((item) => [item.latitude, item.longitude]);
      boundsOptions = {maxZoom: this.props.zoom, padding: [30, 50]}
    }
    if (this.props.items && this.props.items.length == 1 && this.props.zoom) {
      bounds = null;
      center = [this.props.items[0].latitude, this.props.items[0].longitude]
    }

    return <BaseMap center={center} bounds={bounds} boundsOptions={boundsOptions} height={this.props.height}
                    zoom={this.props.zoom}>
      <GeoJSON data={stormarn_map} style={{
        "color": "#22627C",
        "weight": 0,
        "opacity": 0.65,
        "fillOpacity": 0.4,
      }} ref={ref => this.geojson = ref}/>
      <GeoJSON data={lauenburg_map} style={{
        "color": "#9FACE6",
        "weight": 0,
        "opacity": 0.65,
        "fillOpacity": 0.5,
      }} ref={ref => this.geojson = ref}/>
      {this.props.children}
    </BaseMap>;
  }
}

StormarnLauenburgMap.defaultProps = {};
