import CulturalPlaceList from "./components/CulturalPlaceList/CulturalPlaceList";

import React  from "react";
import ReactDOM from 'react-dom';
import ArtSearch from './artworks/ArtSearch.jsx';
// import Flickity from 'flickity';
import Flickity from 'flickity-imagesloaded';
import MapElement from './map/MapElement.jsx';
import GLightbox from "glightbox";

function toArray(obj) {
  var array = [];
  // iterate backwards ensuring that length is an UInt32
  for (var i = obj.length >>> 0; i--;) {
    array[i] = obj[i];
  }
  return array;
}

window.addEventListener("scroll", function() {
    if(document.getElementsByClassName("stage_slide").length) {
        var scrollpos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        var pos = scrollpos/2;

        var els = document.getElementsByClassName("stage_slide");
        Array.prototype.forEach.call(els, function(el) {
            Array.prototype.forEach.call(el.childNodes, function(child) {
                if(child.tagName == "IMG") {
                    child.style.transform = "translate3d(0, "+pos+"px, 0)";

                }
                if(child.classList && child.classList.contains("stage_logo")) {
                    child.style.transform = "translate3d(0, "+pos*0.5+"px, 0)";
                }
            });
        });
    }
});

// window.addEventListener("scroll", function() {
//     if(document.body.classList.contains("subsite")) {
//         return;
//     }
//     const pos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
//     const mainnav = document.body;//document.getElementsByClassName("mainnav")[0];
//
//     //TODO: Direction
//     if(pos > 100) {
//         mainnav.classList.add("mainnav--folded");
//     } else {
//         mainnav.classList.remove("mainnav--folded");
//     }
// });

document.getElementById("mainnav-toggle").addEventListener("click", function() {
    this.parentNode.parentNode.parentNode.parentNode.classList.toggle("nav--open");
})

if(document.getElementById("sitenav-toggle")) {
    document.getElementById("sitenav-toggle").addEventListener("click", function() {
        this.parentNode.classList.toggle("nav--open");
    })
}


let artroot = null;

function hashChange() {
    var location = window.location.hash.replace(/^#\/?|\/$/g, '');
    var application = <ArtSearch location={location} url={artroot.getAttribute("data-url")} filterurl={artroot.getAttribute("data-filters")}/>;
    ReactDOM.render(application, document.getElementById('artworkroot'));
}


if(document.getElementById('artworkroot')) {
    // Split location into `/` separated parts, then render `Application` with it
    artroot = document.getElementById('artworkroot');

    // Handle the initial route and browser navigation events
    hashChange();
    window.addEventListener('hashchange', hashChange, false);
}

const events = document.querySelectorAll('[data-behaviour="event"]');
events.forEach(event => {
    const content = event.querySelector('.event_content');
    autoAnimate(content);
    const header = event.querySelector('.event_header h2');
    const footer = event.querySelector('.event_footer');
    const details = event.querySelector('.event_details');
    const moreLink = event.querySelector('.event_more');
    [header, moreLink].forEach((link) => {
        link.addEventListener("click", function() {
            event.classList.toggle('event--showdetails');
            if (event.classList.contains('event--showdetails')) {
                content.insertBefore(details.cloneNode(true), footer);
                moreLink.innerHTML = "Weniger";
            } else {
                const duplicate = event.querySelector('.event_content > .event_details');
                duplicate.remove();
                moreLink.innerHTML = "Mehr";
            }
        })
    })
})

const lightbox = GLightbox({
    selector: '[data-gallery="gallery"]',
    touchNavigation: true,
    loop: true,
});

import Loader from './loader';
import FilterableList from './components/FilterableList/FilterableList';
import KunstOrteList from './components/KunstOrteList/KunstOrteList';
import SmartEntdeckenList from './components/SmartEntdeckenList/SmartEntdeckenList';
import SmallMap from './components/SmallMap/SmallMap';
import autoAnimate from "@formkit/auto-animate";

const loader = new Loader();

loader.register('FilterableList', FilterableList);
loader.register('KunstOrteList', KunstOrteList);
loader.register('CulturalPlaceList', CulturalPlaceList);
loader.register('SmartEntdeckenList', SmartEntdeckenList);
loader.register('SmallMap', SmallMap);

loader.init(window.document);

document.addEventListener('DOMContentLoaded', function() {
    const digicultList = document.querySelector('[data-behaviour="digicult-list"]');
    if(digicultList) {
        const filterSelects = digicultList.querySelectorAll('select');
        filterSelects.forEach(select => {
            select.addEventListener('change', function() {
                const form = this.closest('form');
                form.submit();
            });
        })
    }
});