import React from 'react';

export default class ArtWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details:""
        };
        this.noToggle = this.noToggle.bind(this);
        this.toggleArtWork = this.toggleArtWork.bind(this);
        this.closeArtWork = this.closeArtWork.bind(this);
    }
    closeArtWork() {
        this.props.onOpen(0);
    }
    noToggle(e) {
        if(this.props.open) {
            e.stopPropagation();
            if(e.target.tagName != "A") {
                e.preventDefault();
                return false;
            }
        }
    }
    toggleArtWork(e) {
        if(this.props.open) {
            this.closeArtWork();
            return;
        }
        const that = this;
        const url = "home/view/" + this.props.data.ID + "?json=1";
        // const url = "details.html?v=1";
        const stateObj = { ArtWork: this.props.data.ID };
        // history.pushState(stateObj, "", "home/view/"+this.props.data.ID);
        fetch(url, {
            credentials: 'same-origin',
        })
        .then(function(response) {
            //Error handling? Response is always 200
            return response.text();
        }).then(function(text) {
            that.setState({
                details:text
            });
            that.props.onOpen(that.props.data.ID);
        });
    }
    renderDetails() {
        return {__html: this.state.details};
    }
    render() {
        const artwork = this.props.data;
        const classname = this.props.open?"ArtWork ArtWork--open" :"ArtWork";
        return <a className="griditem" href={artwork.Link}>
                    <div className="griditem_content">
                        <img src={artwork.ImageUrl}/>
                        <div className="griditem_content_text">
                            <p className="topline">{artwork.City}</p>
                            <h2>{artwork.Title}</h2>
                            <p className="subline">{artwork.ArtistName}</p>
                        </div>
                    </div>
                </a>
        // return <a className={classname} href="javascript:;" onClick={this.toggleArtWork}>
        //            <div className="close"></div>
        //            <div className="tile_image" onClick={this.toggleArtWork}>
        //                 <img src={ArtWork.Image}/>
        //             </div>
        //             <div className="ArtWork_content">
        //                 <div className="ArtWork_content_text">
        //                     <p>{ArtWork.City} {ArtWork.Year}<br/>
        //                     {ArtWork.School}</p>
        //                     <h2>{ArtWork.Title}</h2>
        //                 </div>
        //                 {this.props.open?<div className="ArtWork_details" dangerouslySetInnerHTML={this.renderDetails()} onClick={this.noToggle}>
        //                 </div>:""}
        //             </div>
        //         </a>
    }
}