import React from 'react';
// import cookie from 'react-cookie';
import URI from 'urijs';

import ArtFilter from './ArtFilter.jsx';
import ArtList from './ArtList.jsx';

export default class ArtSearch extends React.Component {
    constructor(props) {
        super(props);
        this.parseLocation = this.parseLocation.bind(this);
        this.navigate = this.navigate.bind(this);
        this.state = {
            query: this.parseLocation(props.location)
        };
    }
    parseLocation(location) {
        const params = URI.parseQuery(location);
        return params;
    }
    componentWillReceiveProps(newProps) {
        // this.setState({location: newProps.location});
        this.setState({query: this.parseLocation(newProps.location)});
        // this.dofetch(newProps.options);
    }
    navigate(change) {
        const query = Object.assign(this.state.query, change);
        window.location.href =
            window.location.pathname + window.location.search + '#' + URI.buildQuery(query)
        ;
        this.setState({query: query});
    }

    render() {
        return <div>
            <header className="header section--white">
                <div className="header_content">
                    <h1>Kunstwerke</h1>
                    <ArtFilter navigate={this.navigate} query={this.state.query} url={this.props.filterurl}/>
                </div>
            </header>
            <ArtList query={this.state.query} querystring={this.props.location} url={this.props.url}/>
        </div>;
    }
}