import React, {Component} from 'react';
import GridItem from "../GridItem/GridItem";
import Filters from "../Filters/Filters";
import URI from "urijs";
import StormarnMap from "../StormarnMap/StormarnMap";
import MapMarker from "../MapMarker/MapMarker";

const parseLocation = (location) => {
    const params = URI.parseQuery(location);
    return params;
}

class SmartEntdeckenList extends Component {
    constructor(props) {
        super(props);
        this.navigate = this.navigate.bind(this);
        this.hashChange = this.hashChange.bind(this);
        const location = window.location.hash.replace(/^#\/?|\/$/g, '');
        this.state = {
            query: parseLocation(location)
        };
    }

    hashChange() {
        const location = window.location.hash.replace(/^#\/?|\/$/g, '');
        this.setState({
            query: parseLocation(location)
        });
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.hashChange, false);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.hashChange, false);
    }

    navigate(change) {
        const query = Object.assign(this.state.query, change);
        window.location.href =
            window.location.pathname + window.location.search + '#' + URI.buildQuery(query)
        ;
        // this.setState({query: query});
    }

    render() {
        const {items, filters} = this.props;

        const {query} = this.state;

        let mapItems = [];

        const filteredItems = items.filter(item => {
            if(query.city && query.city !== item.topline)
                return false;
            if(query.year && item.subline.search(query.year) === -1)
                return false;
            return true;
        });

        const itemMap = filteredItems.reduce((result, item) => {
            if(!result[item.latitude + '/' + item.longitude]) {
                result[item.latitude + '/' + item.longitude] = Object.assign({
                    icon: null, //'person',
                    number: 1,
                    content: '<a href="'+item.href+'">'+item.headline+'</a>'
                }, item)
            } else {
                result[item.latitude + '/' + item.longitude].icon = null; //'people';
                result[item.latitude + '/' + item.longitude].number += 1; //'people';
                result[item.latitude + '/' + item.longitude].content += '<br/>' + '<a href="'+item.href+'">'+item.headline+'</a>';
            }

            return result;
        }, {});
        mapItems = Object.values(itemMap);
        console.log(mapItems);
        //Group

        return (
            <>
                <header className="stage">
                    <div className="map">
                        <StormarnMap items={filteredItems} zoom={14}>
                            {mapItems.map(item => (
                                <MapMarker
                                    position={[
                                        parseFloat(item.latitude),
                                        parseFloat(item.longitude)
                                    ]}
                                    onClick={(e) => {
                                        return;
                                    }}
                                    icon={item.icon}
                                    content={item.number===1 ? '' : item.number}
                                >
                                    {item.content}
                                </MapMarker>
                            ))}
                        </StormarnMap>
                    </div>
                </header>
                <section className="section section--grid section--grid-small">
                    <div className="section_content">
                        <div className="grid_list">
                            {filteredItems.map(item => <GridItem {...item}/>)}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default SmartEntdeckenList;
