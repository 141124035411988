import * as ReactDOM from "react-dom";
import React from "react";

export default class Loader {
    constructor() {
        this.components = {};
        this.register.bind(this);
    }
    register(name, component) {
        this.components[name] = component;
    }
    findProps(node) {
        let result = {};
        let descend = true;
        if(node.getAttribute('data-component-var')) {
            const name = node.getAttribute('data-component-var');
            const val = node.innerHTML;
            result[name] = val;
            descend = false;
        }
        if(node.getAttribute('data-component-json')) {
            const name = node.getAttribute('data-component-json');
            const val = node.innerHTML;
            result[name] = JSON.parse(val);
            descend = false;
        }
        if(node.getAttribute('data-component-attr')) {
            const dataMap = JSON.parse(node.getAttribute('data-component-attr'));
            for (let [name, val] of Object.entries(dataMap)) {
                result[name] = node.getAttribute(val);
            }
        }
        if(node.getAttribute('data-component-list')) {
            const name = node.getAttribute('data-component-list');
            let list = [...node.children].map((child) => {
                return this.findProps(child);
            })
            result[name] = list;
            descend = false;
        }
        if(node.getAttribute('data-component-group')) {
            const name = node.getAttribute('data-component-group');
            const childProps = Object.assign({}, ...[...node.children].map((child) => {
                return this.findProps(child);
            }));
            result[name] = childProps;
            return result;
        }
        if(descend) { //No config here, descend
            const childProps = Object.assign({}, ...[...node.children].map((child) => {
                return this.findProps(child);
            }));
            result = Object.assign(result, childProps);
        }
        return result;
    }
    init(dom) {
        // console.log(this.components);
        const componentNodes = [...dom.querySelectorAll('[data-component]')];

        componentNodes.forEach((node) => {
            const componentName = node.getAttribute('data-component');
            // console.log(componentName);
            const ComponentClass = this.components[componentName];
            if(typeof ComponentClass === 'undefined') {
                alert("Component " + componentName + " not defined.");
                return;
            }

            const props = this.findProps(node);

            console.log('Props', props);
            ReactDOM.render(<ComponentClass {...props} />, node);
        });
    }
}
