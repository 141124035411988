import React from 'react';

import ArtWork from "./ArtWork.jsx";

export default class ArtList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artworks: [],
            lastquery: 'dummydefault',
            open:0
        };
        this.dofetch = this.dofetch.bind(this);
        this.openArt = this.openArt.bind(this);
    }
    dofetch(props) {
        const that = this;

        if(props.querystring == this.state.lastquery)
            return;
        const url = props.url + "?" + props.querystring;
        fetch(url)
            .then(function(response) {
                //Error handling? Response is always 200
                return response.json();
            }).then(function(json) {
                that.setState({
                    artworks: json.artworks,
                    lastquery: props.querystring
                });
            }).catch(function(ex) {
                console.log('parsing failed', ex);
            });
    }
    componentDidMount() {
        this.dofetch(this.props);
    }
    componentWillReceiveProps(newProps) {
        this.dofetch(newProps);
    }
    openArt(Artid) {
        this.setState({
            open:Artid
        });
    }
    render() {
        return <section className="section section--grid section--white">
                <div className="section_content">
                    <div className="grid_list">
                    {this.state.artworks.map((art, i) =>
                        <ArtWork data={art} key={i+1} open={this.state.open==art.ID} onOpen={this.openArt}/>
                    )}
                    </div>
                </div>
            </section>
    }
}