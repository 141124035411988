import React from 'react';

const CulturalPlaceItem = ({href, image, headline, category}) => (
  <a className="griditem" href={href}>
    <div className="griditem_content">
      {image
        ? <img src={image}/>
        : <div className="griditem_dummy"></div>
      }
      <div className="griditem_icon">
        <span className={`cp-icon cp-icon--${category}-hi`}></span>
      </div>
      <div className="griditem_content_text">
        <h2>{headline}</h2>
      </div>
    </div>
  </a>
);

export default CulturalPlaceItem;
